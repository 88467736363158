/* table */
.ant-table-row > td:nth-child(1) {
  border-left: 1px solid #ebedf2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-table-row > td:nth-last-child(1) {
  border-right: 1px solid #ebedf2;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-table-row {
  margin-bottom: 16px;
}
.ant-table-body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.ant-table-thead > tr > th {
  font-weight: 700 !important;
}

/* popover */
.ant-popover .ant-popover-inner {
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}
