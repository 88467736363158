@import './styles/common.css';
@import './styles/font.css';
@import './styles/layout.css';
@import './styles/overview-antd.css';
@import './styles/colors.css';
@import './styles/animation.css';
/* @font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
} */

@font-face {
  font-family: 'Poppins-Black';
  src: url('./assets/fonts/Poppins/Poppins-Black.ttf');
}

html {
  height: 100%;
}

body {
  background-color: rgb(13, 13, 13);
  height: 100%;
  margin: 0;
  font-family: 'Poppins-Black';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 1;
}

#root {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
