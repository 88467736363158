.ml2 {
  margin-left: 2px;
}
.ml4 {
  margin-left: 4px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml14 {
  margin-left: 14px;
}
.ml16 {
  margin-left: 16px;
}
.ml18 {
  margin-left: 18px;
}
.ml20 {
  margin-left: 20px;
}
.ml22 {
  margin-left: 22px;
}
.ml24 {
  margin-left: 24px;
}
.ml26 {
  margin-left: 26px;
}
.ml28 {
  margin-left: 28px;
}
.ml30 {
  margin-left: 30px;
}
.ml32 {
  margin-left: 32px;
}
.ml34 {
  margin-left: 34px;
}
.ml36 {
  margin-left: 36px;
}
.ml38 {
  margin-left: 38px;
}
.ml40 {
  margin-left: 40px;
}
.ml42 {
  margin-left: 42px;
}
.ml44 {
  margin-left: 44px;
}
.ml46 {
  margin-left: 46px;
}
.ml48 {
  margin-left: 48px;
}
.ml50 {
  margin-left: 50px;
}
.ml52 {
  margin-left: 52px;
}
.ml54 {
  margin-left: 54px;
}
.ml56 {
  margin-left: 56px;
}
.ml58 {
  margin-left: 58px;
}
.ml60 {
  margin-left: 60px;
}

.mr2 {
  margin-right: 2px;
}
.mr4 {
  margin-right: 4px;
}
.mr6 {
  margin-right: 6px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr12 {
  margin-right: 12px;
}
.mr14 {
  margin-right: 14px;
}
.mr16 {
  margin-right: 16px;
}
.mr18 {
  margin-right: 18px;
}
.mr20 {
  margin-right: 20px;
}
.mr22 {
  margin-right: 22px;
}
.mr24 {
  margin-right: 24px;
}
.mr26 {
  margin-right: 26px;
}
.mr28 {
  margin-right: 28px;
}
.mr30 {
  margin-right: 30px;
}
.mr32 {
  margin-right: 32px;
}
.mr34 {
  margin-right: 34px;
}
.mr36 {
  margin-right: 36px;
}
.mr38 {
  margin-right: 38px;
}
.mr40 {
  margin-right: 40px;
}
.mr42 {
  margin-right: 42px;
}
.mr44 {
  margin-right: 44px;
}
.mr46 {
  margin-right: 46px;
}
.mr48 {
  margin-right: 48px;
}
.mr50 {
  margin-right: 50px;
}
.mr52 {
  margin-right: 52px;
}
.mr54 {
  margin-right: 54px;
}
.mr56 {
  margin-right: 56px;
}
.mr58 {
  margin-right: 58px;
}
.mr60 {
  margin-right: 60px;
}
.mr70 {
  margin-right: 70px;
}
.mt2 {
  margin-top: 2px;
}
.mt4 {
  margin-top: 4px;
}
.mt6 {
  margin-top: 6px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt14 {
  margin-top: 14px;
}
.mt16 {
  margin-top: 16px;
}
.mt18 {
  margin-top: 18px;
}
.mt20 {
  margin-top: 20px;
}
.mt22 {
  margin-top: 22px;
}
.mt24 {
  margin-top: 24px;
}
.mt26 {
  margin-top: 26px;
}
.mt28 {
  margin-top: 28px;
}
.mt30 {
  margin-top: 30px;
}
.mt32 {
  margin-top: 32px;
}
.mt34 {
  margin-top: 34px;
}
.mt36 {
  margin-top: 36px;
}
.mt38 {
  margin-top: 38px;
}
.mt40 {
  margin-top: 40px;
}
.mt42 {
  margin-top: 42px;
}
.mt44 {
  margin-top: 44px;
}
.mt46 {
  margin-top: 46px;
}
.mt48 {
  margin-top: 48px;
}
.mt50 {
  margin-top: 50px;
}
.mt52 {
  margin-top: 52px;
}
.mt54 {
  margin-top: 54px;
}
.mt56 {
  margin-top: 56px;
}
.mt58 {
  margin-top: 58px;
}
.mt60 {
  margin-top: 60px;
}
.mt84 {
  margin-top: 84px;
}

.mb2 {
  margin-bottom: 2px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb22 {
  margin-bottom: 22px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb26 {
  margin-bottom: 26px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb34 {
  margin-bottom: 34px;
}
.mb36 {
  margin-bottom: 36px;
}
.mb38 {
  margin-bottom: 38px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb42 {
  margin-bottom: 42px;
}
.mb44 {
  margin-bottom: 44px;
}
.mb46 {
  margin-bottom: 46px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb52 {
  margin-bottom: 52px;
}
.mb54 {
  margin-bottom: 54px;
}
.mb56 {
  margin-bottom: 56px;
}
.mb58 {
  margin-bottom: 58px;
}
.mb60 {
  margin-bottom: 60px;
}

.pl2 {
  padding-left: 2px;
}
.pl4 {
  padding-left: 4px;
}
.pl8 {
  padding-left: 8px;
}
.pl10 {
  padding-left: 10px;
}
.pl12 {
  padding-left: 12px;
}
.pl14 {
  padding-left: 14px;
}
.pl16 {
  padding-left: 16px;
}
.pl18 {
  padding-left: 18px;
}
.pl20 {
  padding-left: 20px;
}
.pl22 {
  padding-left: 22px;
}
.pl24 {
  padding-left: 24px;
}
.pl26 {
  padding-left: 26px;
}
.pl28 {
  padding-left: 28px;
}
.pl30 {
  padding-left: 30px;
}
.pl32 {
  padding-left: 32px;
}
.pl34 {
  padding-left: 34px;
}
.pl36 {
  padding-left: 36px;
}
.pl38 {
  padding-left: 38px;
}
.pl40 {
  padding-left: 40px;
}
.pl42 {
  padding-left: 43px;
}
.pl44 {
  padding-left: 44px;
}
.pl46 {
  padding-left: 46px;
}
.pl48 {
  padding-left: 48px;
}
.pl50 {
  padding-left: 50px;
}

.pr2 {
  padding-right: 2px;
}
.pr4 {
  padding-right: 4px;
}
.pr8 {
  padding-right: 8px;
}
.pr10 {
  padding-right: 10px;
}
.pr12 {
  padding-right: 12px;
}
.pr14 {
  padding-right: 14px;
}
.pr16 {
  padding-right: 16px;
}
.pr18 {
  padding-right: 18px;
}
.pr20 {
  padding-right: 20px;
}
.pr22 {
  padding-right: 22px;
}
.pr24 {
  padding-right: 24px;
}
.pr26 {
  padding-right: 26px;
}
.pr28 {
  padding-right: 28px;
}
.pr30 {
  padding-right: 30px;
}
.pr32 {
  padding-right: 32px;
}
.pr34 {
  padding-right: 34px;
}
.pr36 {
  padding-right: 36px;
}
.pr38 {
  padding-right: 38px;
}
.pr40 {
  padding-right: 40px;
}
.pr42 {
  padding-right: 43px;
}
.pr44 {
  padding-right: 44px;
}
.pr46 {
  padding-right: 46px;
}
.pr48 {
  padding-right: 48px;
}
.pr50 {
  padding-right: 50px;
}

.pt2 {
  padding-top: 2px;
}
.pt4 {
  padding-top: 4px;
}
.pt8 {
  padding-top: 8px;
}
.pt10 {
  padding-top: 10px;
}
.pt12 {
  padding-top: 12px;
}
.pt14 {
  padding-top: 14px;
}
.pt16 {
  padding-top: 16px;
}
.pt18 {
  padding-top: 18px;
}
.pt20 {
  padding-top: 20px;
}
.pt22 {
  padding-top: 22px;
}
.pt24 {
  padding-top: 24px;
}
.pt26 {
  padding-top: 26px;
}
.pt28 {
  padding-top: 28px;
}
.pt30 {
  padding-top: 30px;
}
.pt32 {
  padding-top: 32px;
}
.pt34 {
  padding-top: 34px;
}
.pt36 {
  padding-top: 36px;
}
.pt38 {
  padding-top: 38px;
}
.pt40 {
  padding-top: 40px;
}
.pt42 {
  padding-top: 43px;
}
.pt44 {
  padding-top: 44px;
}
.pt46 {
  padding-top: 46px;
}
.pt48 {
  padding-top: 48px;
}
.pt50 {
  padding-top: 50px;
}

.pb2 {
  padding-bottom: 2px;
}
.pb4 {
  padding-bottom: 4px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb14 {
  padding-bottom: 14px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb18 {
  padding-bottom: 18px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb22 {
  padding-bottom: 22px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb26 {
  padding-bottom: 26px;
}
.pb28 {
  padding-bottom: 28px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb32 {
  padding-bottom: 32px;
}
.pb34 {
  padding-bottom: 34px;
}
.pb36 {
  padding-bottom: 36px;
}
.pb38 {
  padding-bottom: 38px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb42 {
  padding-bottom: 43px;
}
.pb44 {
  padding-bottom: 44px;
}
.pb46 {
  padding-bottom: 46px;
}
.pb48 {
  padding-bottom: 48px;
}
.pb50 {
  padding-bottom: 50px;
}

.h2 {
  height: 2px;
}
.h4 {
  height: 4px;
}
.h6 {
  height: 6px;
}
.h8 {
  height: 8px;
}
.h10 {
  height: 10px;
}
.h12 {
  height: 12px;
}
.h14 {
  height: 14px;
}
.h16 {
  height: 16px;
}
.h18 {
  height: 18px;
}
.h20 {
  height: 20px;
}
.h22 {
  height: 22px;
}
.h24 {
  height: 24px;
}
.h26 {
  height: 26px;
}
.h28 {
  height: 28px;
}
.h30 {
  height: 30px;
}
.h32 {
  height: 32px;
}
.h34 {
  height: 34px;
}
.h36 {
  height: 36px;
}
.h38 {
  height: 38px;
}
.h40 {
  height: 40px;
}
.h42 {
  height: 42px;
}
.h44 {
  height: 44px;
}
.h46 {
  height: 46px;
}
.h48 {
  height: 48px;
}
.h50 {
  height: 50px;
}
.h52 {
  height: 52px;
}
.h54 {
  height: 54px;
}
.h56 {
  height: 56px;
}
.h60 {
  height: 60px;
}
.h72 {
  height: 72px;
}
.h80 {
  height: 80px;
}

.w2 {
  width: 2px;
}
.w4 {
  width: 4px;
}
.w6 {
  width: 6px;
}
.w8 {
  width: 8px;
}
.w10 {
  width: 10px;
}
.w12 {
  width: 12px;
}
.w14 {
  width: 14px;
}
.w16 {
  width: 16px;
}
.w18 {
  width: 18px;
}
.w20 {
  width: 20px;
}
.w22 {
  width: 22px;
}
.w24 {
  width: 24px;
}
.w26 {
  width: 26px;
}
.w28 {
  width: 28px;
}
.w30 {
  width: 30px;
}
.w32 {
  width: 32px;
}
.w34 {
  width: 34px;
}
.w36 {
  width: 36px;
}
.w38 {
  width: 38px;
}
.w40 {
  width: 40px;
}
.w42 {
  width: 42px;
}
.w44 {
  width: 44px;
}
.w46 {
  width: 46px;
}
.w48 {
  width: 48px;
}
.w72 {
  width: 72px;
}
.w60 {
  width: 60px;
}
.w80 {
  width: 80px;
}

.h100 {
  height: 100%;
}

.w50 {
  width: 50%;
}
.w100 {
  width: 100%;
}

.bd-box {
  box-sizing: border-box;
}

.rotate45 {
  transform: rotate(45deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate135 {
  transform: rotate(135deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate225 {
  transform: rotate(225deg);
}
.rotate270 {
  transform: rotate(270deg);
}
