.bg-main {
  background-color: #f5f7fb;
}
.bg-red {
  background-color: #ff3838;
}
.bg-gray {
  background-color: #212130;
}
.bg-white {
  background-color: #fff;
}

.white {
  color: white !important;
}
.black {
  color: black !important;
}
.dark {
  color: #202025 !important;
}
.purple {
  color: #761de2 !important;
}
.gray {
  color: #939393 !important;
}
.gray1 {
  color: #191b23 !important;
}
.gray3 {
  color: #3d3d3d !important;
}
.gray6 {
  color: #666 !important;
}

.red {
  color: #f2271c !important;
}
.red8 {
  color: #8d0104 !important;
}
.yellow {
  color: #ffa800 !important;
}
.green {
  color: rgb(25 209 132) !important;
}

.main {
  color: #9c88f8 !important;
}
