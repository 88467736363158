.loading {
  animation: loading_name 0.5s linear infinite;
}
@keyframes loading_name {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loadingSkeletonAnimation {
  animation-name: skeleton;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: linear-gradient(143deg, rgba(142, 44, 227, 0.3) -4.18%, rgba(75, 0, 223, 0.3) 92.28%);
}
@keyframes skeleton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}

.type_animation {
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  width: 0;
  animation: typing 3s steps(25, end) forwards infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: orange;
  }
}
