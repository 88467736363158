.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.fw800 {
  font-weight: 800 !important;
}
.fw900 {
  font-weight: 900 !important;
}
.fz8 {
  font-size: 8px !important;
}
.fz9 {
  font-size: 9px !important;
}
.fz10 {
  font-size: 10px !important;
}
.fz12 {
  font-size: 12px !important;
}
.fz14 {
  font-size: 14px !important;
}
.fz16 {
  font-size: 16px !important;
}
.fz18 {
  font-size: 18px !important;
}
.fz20 {
  font-size: 20px !important;
}
.fz22 {
  font-size: 22px !important;
}
.fz24 {
  font-size: 24px !important;
}
.fz26 {
  font-size: 26px !important;
}
.fz28 {
  font-size: 28px !important;
}
.fz30 {
  font-size: 30px !important;
}
.fz32 {
  font-size: 32px !important;
}
.fz34 {
  font-size: 34px !important;
}
.fz36 {
  font-size: 36px !important;
}
.fz38 {
  font-size: 38px !important;
}
.fz40 {
  font-size: 40px !important;
}
.fz48 {
  font-size: 48px !important;
}
.fz80 {
  font-size: 80px !important;
}

.br4 {
  border-radius: 4px !important ;
}
.br6 {
  border-radius: 6px !important ;
}
.br8 {
  border-radius: 8px !important ;
}
.br10 {
  border-radius: 10px !important ;
}
.br12 {
  border-radius: 12px !important ;
}
.br16 {
  border-radius: 16px !important ;
}
.br20 {
  border-radius: 20px !important ;
}
.br24 {
  border-radius: 24px !important ;
}
.br50 {
  border-radius: 50% !important;
}

.lh48 {
  line-height: 48px !important;
}
.ffb {
  font-family: 'Poppins-Bold';
}

.ffl {
  font-family: 'Poppins-Light';
}
