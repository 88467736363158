.fx-row {
  display: flex;
  flex-direction: row;
}
.fx-col {
  display: flex;
  flex-direction: column;
}
.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.jc-end {
  justify-content: end;
}
.jc-ct {
  justify-content: center;
}
.ai-ct {
  align-items: center;
}
.ai-end {
  align-items: flex-end;
}
.as-end {
  align-self: flex-end;
}
.ai-start {
  align-items: flex-start;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline {
  display: inline !important;
}
.fx {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ta-center {
  text-align: center;
}
.ta-left {
  text-align: left;
}
.ta-right {
  text-align: right;
}

.bd1 {
  border: 1px solid #fff;
}
.bd2 {
  border: 1px solid blue;
}
.bd3 {
  border: 1px solid #333;
}
.bd4 {
  border: 1px solid pink;
}
.bd5 {
  border-bottom: 2px dashed #c6c6c6;
}
.bd6 {
  border-bottom: 1px solid #c6c6c6;
}
.bd7 {
  border: 1px solid #333;
}
.bd8 {
  border: 1px solid transparent;
}

.bb1 {
  border-bottom: 1px solid #ffffff1a;
}
.br1 {
  border-right: 1px solid #ffffff1a;
}
.bl1 {
  border-left: 1px solid #ffffff1a;
}
.bt1 {
  border-top: 1px solid #ffffff1a;
}
.scroll-x {
  overflow-x: scroll;
}
.scroll-y {
  overflow-y: scroll;
}
.scroll-y-auto {
  overflow-y: auto;
}
.hide-x {
  overflow-x: hidden;
}
.hide-y {
  overflow-y: hidden;
}
.text-warp {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hide {
  overflow: hidden;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: rgba(51, 51, 51, 0.1);
  border-radius: 4px;
}
.ellipsis::after {
  content: '...';
}
.line1 {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.line2 {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.absolute-bottom {
  position: absolute;
  bottom: 40px;
}

.textarea {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 12px;
}
.textarea::placeholder {
  color: #939393;
  font-size: 14px;
  font-weight: 400;
}

.textarea:focus {
  outline: none;
}

.click {
  cursor: pointer;
  pointer-events: auto;
}

.arrow {
  height: 4.77px;
  width: 8px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 24px;
  margin-right: 24px;
}

.nav {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid transparent;
}
.scroll_nav {
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ffffff1a;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(13, 13, 13);
  max-width: 1040px;
  z-index: 99;
}

.menu {
  padding: 5px 0;
  width: 25px;
  height: 3px;
  background: #fff;
  background-clip: content-box;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

#close {
  display: inline-block;
  width: 23px;
  height: 2px;
  background: #fff;
  transform: rotate(45deg);
}
#close::after {
  content: '';
  display: block;
  width: 23px;
  height: 2px;
  background: #fff;
  transform: rotate(-90deg);
}
